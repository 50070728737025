import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { SEO } from '../components/seo';
import Layout from '../components/layout';
import { dots } from '../components/layout.module.css';
import dotImage from '../images/dots-light.png';

// Basic template for basic pages

function Page({ pageContext }) {
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title,
    description: page.frontmatter.description,
  };
  return (
    <Layout meta={meta} pageTitle={meta.title}>
      <div>
        <MDXRenderer>{page.body}</MDXRenderer>
      </div>
      <img className={dots} src={dotImage} alt="dots" />
    </Layout>
  );
}

export default Page;

export const Head = (pageContext) => <SEO pageContext={pageContext} />;
