import React from 'react';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export const SEO = ({ pageContext, children }) => {
  const { location } = pageContext;
  const description = pageContext?.pageContext?.frontmatter?.metaDesc;
  const fallbackDesc = pageContext?.pageContext?.frontmatter?.excerpt;
  const { description: defaultDescription, siteUrl } = useSiteMetadata();

  console.log(pageContext?.pageContext?.frontmatter?.excerpt);

  const seo = {
    description: description || fallbackDesc || defaultDescription,
    siteUrl,
  };

  const pathname = location?.pathname || pageContext?.fields?.slug;
  const canonicalUrl =
    pathname === '/' ? seo.siteUrl : `${seo.siteUrl}${pathname}`;

  return (
    <>
      <meta name="description" content={seo.description} />
      <link rel="canonical" href={canonicalUrl} />
      {children}
    </>
  );
};
